.App {
  text-align: center;
}

.overflow-handler {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul>li>a {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shaky {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-2px);
  }

  40% {
    transform: translateX(2px);
  }

  60% {
    transform: translateX(-2px);
  }

  80% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-shaky {
  animation: none;
}

.animate-shaky:hover {
  animation: shaky 1s infinite;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.floating-div {
  animation: float 3s ease-in-out infinite;
}

@keyframes float2 {

  0%,
  100% {
    transform: translate(-5px, -5px);
  }

  50% {
    transform: translate(10px, -5px);
  }
}

.floating-div2 {
  animation: float2 4s ease-in-out infinite;
}

.bg-gradient {
  background-color: hsla(0, 0%, 0%, 1);
  background-image:
    radial-gradient(at 93% 96%, hsla(24, 85%, 37%, 1) 0px, transparent 50%),
    radial-gradient(at 73% 18%, hsla(189, 70%, 47%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(355, 62%, 23%, 1) 0px, transparent 50%),
    radial-gradient(at 92% 21%, hsla(340, 60%, 10%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(17, 71%, 26%, 1) 0px, transparent 50%),
    radial-gradient(at 29% 47%, hsla(240, 64%, 25%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(343, 73%, 24%, 1) 0px, transparent 50%);
}

.bg-gradient2 {
  /* background: rgb(97, 9, 11);
  background: -moz-radial-gradient(circle, rgba(97, 9, 11, 1) 0%, rgba(14, 3, 3, 1) 45%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-radial-gradient(circle, rgba(97, 9, 11, 1) 0%, rgba(14, 3, 3, 1) 45%, rgba(0, 0, 0, 1) 100%);
  background: radial-gradient(circle, rgba(97, 9, 11, 1) 0%, rgba(14, 3, 3, 1) 45%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#61090b", endColorstr="#000000", GradientType=1); */

  background: rgb(232, 65, 66);
  background: -moz-linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  background: linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e84142", endColorstr="#e9da24", GradientType=1);
}

.bg-gradient3 {
  /* background: rgb(119, 29, 29);
  background: linear-gradient(90deg, rgba(119, 29, 29, 1) 0%, rgba(134, 18, 18, 1) 55%, rgba(92, 83, 83, 1) 100%); */
  /* 
  background: rgb(77, 9, 10);
  background: -moz-radial-gradient(circle, rgba(77, 9, 10, 1) 0%, rgba(4, 1, 1, 1) 20%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-radial-gradient(circle, rgba(77, 9, 10, 1) 0%, rgba(4, 1, 1, 1) 20%, rgba(0, 0, 0, 1) 100%);
  background: radial-gradient(circle, rgba(77, 9, 10, 1) 0%, rgba(4, 1, 1, 1) 20%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d090a", endColorstr="#000000", GradientType=1); */


  background: rgb(232, 65, 66);
  background: -moz-linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  background: linear-gradient(180deg, rgba(232, 65, 66, 1) 0%, rgba(233, 218, 36, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e84142", endColorstr="#e9da24", GradientType=1);
  /* Adjust the blur amount */
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff !important;
  /* Your desired color */
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
  /* Your desired color */
}


#tsparticles canvas {
  position: relative !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 1px;
  /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* Change the background color of the track */
}

::-webkit-scrollbar-thumb {
  background: rgb(166, 192, 211);
  /* Change the color of the scrollbar thumb */
  border-radius: 4px;
  /* Add rounded corners to the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Change the color of the scrollbar thumb on hover */
}